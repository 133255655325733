.App {
  font-family: "Manrope", sans-serif !important;
  text-align: center;
  height: 99.5%;
}

.button-wrapper button {
  z-index: 1;
  height: 30px;
  max-width: 500px;
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
  border: none;
  color: white;
  background-color: #0d6efd;
}
.button-wrapper {
  float: left;
}
.excalidraw .App-menu_top .buttonList {
  display: flex;
}

:root[dir="ltr"]
  .excalidraw
  .layer-ui__wrapper
  .zen-mode-transition.App-menu_bottom--transition-left {
  transform: none;
}

.excalidraw .panelColumn {
  text-align: left;
}

.export-wrapper {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.checkboxes {
  text-align: center;
}

.checkboxes input {
  margin: 0px 6px 0px 0px;
}

.checkboxes label {
  margin: 0px 20px 0px 8px;
}
.export-wrapper__checkbox input {
  margin-top: 6px;
  margin: 6px;
}
.excalidraw {
  position: relative;
  overflow: hidden;
  color: var(--text-primary-color);
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 1px 1px 5px 5px;
}
.btn-clear {
  background-color: #226064 !important;
  width: 80px;
  font-size: small;
  height: 30px;
}
.btn-clear:hover {
  background-color: #067d83 !important;
  width: 80px;
  font-size: small;
  height: 30px;
}
.App-toolbar-content {
  display: none !important;
}