#firepad-container {
  width: 100%;
  margin: auto;
  border-radius: 5px;
}

#output-container {
  width: 49%;
  height: 100%;
  margin-left: 15px;
  background-color: rgb(32, 34, 37);
  border-radius: 5px 5px 2px 2px;
}

#input-container {
  width: 49%;
  height: 100%;
  border-radius: 5px;
  background-color: rgb(54, 57, 63);
  /* box-shadow: rgb(0 0 0 / 18%) 0px 4px 4px; */
}

.top-bar {
  height: 40px;
  justify-content: center;
  text-align: center;
  margin: 10px;
  border-radius: 5px;
}

.editor-area {
  display: flex;
  margin-top: 5px;
  margin-left: 18px;
  height: 96vh;
  max-height: 880px;
}

@media screen and (max-width: 1030px) {
  select {
    color: rgb(240, 141, 73);
    border: 0px 0px 3px 0px;
    padding: 2px;
    background-color: rgb(54, 57, 63);
    width: 10px;
  }

  .select-name {
    float: none;
  }

  .btn-danger {
    float: none;
    background: rgb(241, 76, 78);
    border-radius: 5px;
    width: 10px;
    font-size: small;
    padding: 3px;
  }
}

.select {
  margin: 2px;
  font-size: small;
  border: none;
  border-radius: 5px;
  color: #0d6efd;
}

select {
  color: rgb(238, 214, 57);
  border: 0px 0px 3px 0px;
  padding: 6px;
  background-color: rgb(54, 57, 63);
  width: 110px;
  border-bottom: 1px solid rgb(238, 214, 57) !important;
  border: none;
}

option {
  color: rgb(238, 214, 57);
  width: 50px;
  height: 20px;
  display: flex;
}

.select-name {
  float: right;
}

.btn-danger {
  float: left;
  border: none;
  background: rgb(241, 76, 78);
  border-radius: 5px;
  font-size: small;
}

.btn-white {
  background: none;
  border: none;
  border-radius: 2px;
  padding: 5px;  
  width: 100px;
}

.btn-white:hover {
  background: none;
  border: none;
  border-radius: 5px;
  padding: 5px;
  background-color: rgb(66, 68, 73);
  width: 100px;
}

.btn-select:hover {
  background: none;
  border: none;
  /* border-radius: 2px; */
  padding: 5px;
  background-color: rgb(66, 68, 73);
  border-bottom: 1.2px solid rgb(238, 214, 57);
  width: 100px;
}

.btn-select {
  background: none;
  border: none;
  padding: 5px;
  border-radius: 0px 2px 0px 0px;
  border-bottom: 1.2px solid rgb(238, 214, 57);
  width: 100px;
}

.body {
  background: #fdfdfd !important;
  font-family: "Manrope", sans-serif !important;
  font-size: 0.875rem;
  height: 840px;
  margin: auto;
}

/* body {
  height: 96.4%;
  background-color: rgb(32, 34, 37);
  font-size: 0.875rem;
  border-radius: 5px 5px 0px 0px;
} */

.body::selection {
  background: rgb(24, 25, 28);
  font-family: "Manrope", sans-serif !important;
}

#body-container {
  height: 91%;
}

.btn-default {
  margin: 2px;
  margin-top: 5%;
  width: 13%;
  font-weight: normal;
  justify-content: start;
  text-align: start;
  border: none;
  border-bottom: 2px solid gainsboro;
  padding: 5px;
  background-color: #fdfdfd !important;
}

.btn-light {
  margin: 2px;
  margin-top: 5%;
  width: 13%;
  font-weight: bold;
  justify-content: start;
  text-align: start;
  border: none;
  background-color: #fdfdfd !important;
  border-bottom: 2px solid #3aa4f0;
  padding: 5px;
}

.btn-light:hover {
  margin: 2px;
  margin-top: 5%;
  width: 13%;
  font-weight: bold;
  justify-content: start;
  text-align: start;
  border: none;
  background-color: #fdfdfd !important;
  border-bottom: 2px solid #3aa4f0;
  padding: 5px;
}

.btn-cancel {
  float: right;
  color: white;
  border: none;
  background: none;
  background-color: rgb(241, 28, 28);
  /* box-shadow: rgb(0 0 0 / 18%) 1px 2px 3px 3px; */
  border-radius: 3px;
  width: 5%;
  padding: 0.5%;
}

.btn-cancel:hover {
  float: right;
  color: white;
  border: none;
  background-color: rgb(255, 1, 1);
  box-shadow: rgb(255, 1, 1) 0px 0px 0px 2px;
  border-radius: 3px;
  width: 5%;
  padding: 0.5%;
}

.navbar {
  background-color: rgb(54, 57, 63);
  width: 100%;
  height: 60px;
  font-family: "Manrope", sans-serif !important;
  border-radius: 5px 5px 2px 2px;
  border-bottom:none;
}

.nav-item {
  width: 100%;
  position: flex;
  border-radius: 5px;
}
.navbar-nav{
  width: 100%;
  background: none;
}

@media screen and (max-width: 1100px) {
.nav-item {
  width: 70% !important;
  position: flex;
  border-radius: 5px;
  }
}

.modal {
  height: 100%;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: -47%;
  margin-top: 23%;
  height: 60%;
  width: 1000px;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  border: none;
  border-radius: 0.2rem;
  /* box-shadow: rgb(98, 59, 88) 2px 2px 6px 4px; */
  outline: 0;
}

.submit-content{
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 14%;
  width: 300px;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 0.2rem;
  /* box-shadow: rgb(98, 59, 88) 2px 2px 6px 4px; */
  outline: 0;
}

.submit-modal{
  margin-top: 5%;
  width: 20%;
  margin-left: 37%;
}

.submit-cancel{
  border: none;
  background: none;
  background-color: rgb(241, 76, 78);
  color: #fff;
  border-radius: 3px;
  width: 70px;
}

.submit-cancel:hover{
  border: none;
  background: none;
  background-color: rgb(180, 54, 56);
  color: #fff;
  border-radius: 3px;
  width: 70px;
}

.submited{
  border: none;
  background: none;
  background-color: #226064 !important;
  color: #fff;
  border-radius: 3px;
  width: 70px;
}

.submited:hover{
  border: none;
  background: none;
  background-color: #158b94 !important;
  color: #fff;
  border-radius: 3px;
  width: 70px;
}

.bx--header {
  background-color: rgb(98, 59, 88);
  height: 3.5rem;
}

img {
  display: flex;
  width: 64px;
  height: 35px;
  cursor: pointer;
}

.submit-button {
  background-color: #226064 !important;
  color: white !important;
  float: right;
  height: 32px;
  margin-top: 5%;
  border: none;
  border-color: none;
  border-radius: 3px;
}

.submit-button:hover {
  background-color: #067d83 !important;
  color: white !important;
  float: right;
  height: 32px;
  margin-right: 24px;
  margin-top: 5%;
  border: none;
  border-color: none;
  border-radius: 3px;
}
.editor *{
  font-family: "Monaco", "Menlo", "Ubuntu Mono", "Droid Sans Mono", "Consolas", monospace !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  }
  @media screen and (max-width: 1200px) {
  .topbar-buttons{
    margin-top: 20px;
  }
}
@media (min-width: 500px) and (max-width: 1056px) {
  .bx--col-lg-6{
  width: 40%;
  }

  h3{
      font-size: 1.5rem;
  }

}
.languageChange{
  float: right !important;
  background: none;
  border: none;
  color: white;
}