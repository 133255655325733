.xpa-vi--step-c {
  background: #ffc83d !important;
}

#xpa-vi--otp {
  background: none !important;
  border: 1px solid black;
  font-size: 20px;
}

.img_height {
  height: 100px;
  width: 150px;
}

.spinner-grow{
  justify-content: center;
  width: 30px;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.viewLoader{
  /* margin-left: 45%; */
  margin-top: 20%;
}