.textarea {
  width: 100%;
  height: 100.5%;
  outline: none;
  padding: 12px 20px;
  position: relative;
  color: white;
  border: none;
  border-radius: 0px 0px 5px 5px;
  background-color: #263238;
  font-size: 16px;
  resize: none;
  vertical-align: middle;
  font-family: "Manrope", sans-serif !important;
}
.outer-textarea {
  height: 100%;
  /* background-color: #263238; */
}
.matrix-input {
  margin-left: 10px;
  font-size: 12px;
}
.matrix-comment {
  background: rgb(47, 49, 54);
  width: 100%;
  height: 50%;
  text-align: left;
  margin-top: 10%;
  border-radius: 5px;
}
pre {
  word-spacing: normal;
  word-break: normal;
  color: rgb(204, 204, 204);
  tab-size: 4;
  hyphens: none;
}
@media screen and (max-width: 1030px) {
  pre {
    word-spacing: normal;
    word-break: normal;
    color: rgb(204, 204, 204);
    tab-size: 4;
    hyphens: none;
    overflow-x: scroll;
    overflow-y: hidden;
  }
}
.token-comment,
.token-block-comment,
.token-prolog,
.token-doctype,
.token-output,
.token-cdata {
  color: rgb(153, 153, 153);
}
.token-output {
  color: rgb(153, 153, 153);
}
.token-data {
  height: 60%;
}
.token-operator,
.token-entity,
.token-url {
  color: rgb(103, 205, 204);
  margin: 4px;
}
.token-punctuation {
  margin-left: 20px;
}
.token-selector,
.token-important,
.token-atrule,
.token-keyword,
.token-builtin {
  color: rgb(204, 153, 205);
  margin-right: 4px;
}
.token-body {
  height: 160px;
}
.token-boolean,
.token-number,
.token-function {
  color: rgb(240, 141, 73);
  margin: 4px;
}
.spinner-border {
  justify-content: center;
  align-items: center;
  padding: 20%;
}
.token-output-data {
  margin-bottom: 7px;
}
.questions {
  margin: 7px;
}
.questions-input {
  /* display: flex; */
  margin-left: 1%;
  height: 96vh;
  max-height: 880px;
}
.sk-circle {
  margin: 100px auto;
  width: 80px;
  height: 80px;
  position: relative;
}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: rgb(238, 214, 57);
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
#ques-input-container {
  width: 100%;
  height: 40%;
  margin: auto;
  border-radius: 5px;
  background-color: rgb(47, 49, 54);
}
* {
  box-sizing: border-box;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  padding: 10px;
  height: 300px; /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.activeButton{
  width: 30px;
  margin: 10px;
  border: none;
  background: none;
  color: white;
  background-color: rgb(131, 10, 100);
  border-radius: 2px;
}
.activeButton:hover{
  width: 30px;
  margin: 10px;
  border: none;
  background: none;
  color: white;
  background-color: rgb(119, 38, 99);
  border-radius: 2px;
}

.defaultButton{
  width: 30px;
  margin: 10px;
  border: none;
  background: none;
  color: white;
  background-color: rgb(98, 59, 88);
  border-radius: 2px;
}